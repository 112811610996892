import i18n from "@/i18n";

export interface Role {
  id: string;
  name: RoleValues;
}

export interface PopulatedRole extends Role {
  legacy: string[];
  label: string;
  privileges: string[];
  denyFields: string[];
}

export type RoleValues =
  | "demo"
  | "admin"
  | "doctor"
  | "financial"
  | "sme"
  | "delegate"
  | "administrative"
  | "agent"
  | "pm"
  | "manager";

export const RolesMapping: {
  Delegate: Role;
  Financial: Role;
  SME: Role;
  Doctor: Role;
  DemoDoctor: Role;
  Administrative: Role;
  Admin: Role;
  Agent: Role;
  PM: Role;
  Manager: Role;
} = {
  DemoDoctor: {
    name: "demo",
    id: "UxGHQLdFJfUuDmDV5rOM",
  },
  Admin: {
    name: "admin",
    id: "21232f297a57a5a743894a0e4a801fc3",
  },
  Administrative: {
    name: "administrative",
    id: "09707f5ba4ef21bcd3baa99ed5c10d12",
  },
  Doctor: {
    name: "doctor",
    id: "f9f16d97c90d8c6f2cab37bb6d1f1992",
  },
  Financial: {
    name: "financial",
    id: "6d84a9ae99910a7be5458a15c82a968c",
  },
  SME: {
    name: "sme",
    id: "aaa455f8dfbb37e14d24f7d9c0b7928f",
  },
  Delegate: {
    name: "delegate",
    id: "7f662005788ab434b371fbb0efc6d45f",
  },
  Agent: {
    name: "agent",
    id: "b33aed8f3134996703dc39f9a7c95783",
  },
  PM: {
    name: "pm",
    id: "5109d85d95fece7816d9704e6e5b1279",
  },
  Manager: {
    name: "manager",
    id: "GSjlRs4nL5WbKnNpBsUQ",
  },
};

export const evalRoleSelectOptions = () =>
  Object.keys(RolesMapping).map((roleKey) => {
    const roleName = RolesMapping[roleKey as keyof typeof RolesMapping].name;
    return {
      label: i18n.t(`baseUser.role.${roleName.toLowerCase()}`),
      value: roleName,
    };
  });

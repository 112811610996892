import { type Replace } from "@/utils/typeCheck";

enum Pages {
  ROOT = "/",
  FOUR_ZERO_FOUR = "/404",
  ASSISTANT_DICOM = "/assistantDicom",
  LOGIN = "/login",
  SIGN_UP = "/signup",
  SIGN_UP_DEMO_USER = "/signupDemoUser",
  FORGOT_PASSWORD = "/forgotPassword",
  HOME = "/home",
  CASE_LIST = "/cases",
  CASE_DEMO_LIST = "/demoCases",
  CASE_EDIT = "/cases/:id",
  CASE_EDIT_STEP_ONE = "/cases/:id/stepOne",
  CASE_EDIT_STEP_TWO = "/cases/:id/stepTwo",
  CASE_EDIT_STEP_THREE = "/cases/:id/stepThree",
  CASE_EDIT_STEP_FOUR = "/cases/:id/stepFour",
  CASE_ADD = "/cases/add",
  ORDERS = "/orders",
  ORDERS_ADD = "/orders/add",
  ORDERS_EDIT = "/orders/:id",
  DELEGATE_LIST = "/delegates",
  USERS_LIST = "/users",
  USERS_ADD = "/users/add",
  USERS_EDIT = "/users/:id",
  ORGANIZATIONS = "/organizations",
  SPECIALIZATIONS = "/specializations",
  DISTRIBUTORS = "/distributors",
  ORGANIZATIONS_ADD = "/organizations/add",
  ORGANIZATIONS_EDIT = "/organizations/:id",
  PASSWORD_EDIT = "/passwordEdit",
  PERSONAL_DATA = "/personalData",
  GUIDELINES = "/guidelines",
  GUIDELINES_UPLOAD_FILE = "/guidelines-upload-file",
  GUIDELINES_UPLOAD_VIDEO = "/guidelines-upload-video",
  EMAIL_ACTION_HANDLER = "/email-action-handler",
}

export default Pages;

export const formatDynamicPage = <
  P extends string = Pages,
  ReplaceWithId extends string | undefined = string,
  ReplaceId extends string = ":id"
>(
  root: P,
  replaceWithId: ReplaceWithId,
  replaceId = ":id" as ReplaceId
) =>
  root.replace(replaceId, replaceWithId ?? "") as Replace<
    `${P}`,
    ReplaceId,
    ReplaceWithId extends string ? ReplaceWithId : ""
  >;
